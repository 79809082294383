/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * FleetOnRoute API
 * <p>This API powers <a href='https://fleetonroute.com'>https://fleetonroute.com</a>.</p>    <p>To begin, authenticate using the <a href='#/auth/login'>Login</a> method below with your credentials. </p>    <p>To create an account, please log on to <a href='https://portal.fleetonroute.com'>https://portal.fleetonroute.com</a></p>    <p><a href=\"/public/oss-licenses.txt\">Open Source Attributions</a></p>
 *
 * OpenAPI spec version: 1.22.0
 * Contact: support@fleetonroute.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ActionToggle
 */
export interface ActionToggle {
    /**
     * 
     * @type {string}
     * @memberof ActionToggle
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof ActionToggle
     */
    allowed: string;
}
/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    keyId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    userProfileId: string;
    /**
     * 
     * @type {any}
     * @memberof ApiKey
     */
    organizationId: any;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    createdBy: string;
    /**
     * 
     * @type {number}
     * @memberof ApiKey
     */
    createdAt: number;
    /**
     * 
     * @type {any}
     * @memberof ApiKey
     */
    extra?: any;
}
/**
 * 
 * @export
 * @interface ApiVersion
 */
export interface ApiVersion {
    /**
     * 
     * @type {number}
     * @memberof ApiVersion
     */
    major: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVersion
     */
    minor: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVersion
     */
    patch: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVersion
     */
    full: string;
}
/**
 * 
 * @export
 * @interface AuthUser
 */
export interface AuthUser {
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUser
     */
    emailVerified: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    idToken: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    uid: string;
}
/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    address?: string;
    /**
     * 
     * @type {LatLng}
     * @memberof Client
     */
    location?: LatLng;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    homePhone: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    cellPhone: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    email: string;
    /**
     * Extra client properties can be stored as a Map<String,any>
     * @type {any}
     * @memberof Client
     */
    extra: any;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    updatedBy: string;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Client
     */
    updatedAt: number;
    /**
     * 
     * @type {ClientOptions}
     * @memberof Client
     */
    options: ClientOptions;
}
/**
 * 
 * @export
 * @interface ClientOptions
 */
export interface ClientOptions {
    /**
     * 
     * @type {boolean}
     * @memberof ClientOptions
     */
    enableNotifications: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientOptions
     */
    primaryNotificationMethod: ClientOptions.PrimaryNotificationMethodEnum;
}

/**
 * @export
 * @namespace ClientOptions
 */
export namespace ClientOptions {
    /**
     * @export
     * @enum {string}
     */
    export enum PrimaryNotificationMethodEnum {
        SMS = <any> 'SMS',
        EMAIL = <any> 'EMAIL'
    }
}
/**
 * 
 * @export
 * @interface CreateApiKeyRequest
 */
export interface CreateApiKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateApiKeyRequest
     */
    userProfileId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiKeyRequest
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApiKeyRequest
     */
    version: string;
    /**
     * 
     * @type {any}
     * @memberof CreateApiKeyRequest
     */
    extra?: any;
}
/**
 * 
 * @export
 * @interface CreateInvitationPayload
 */
export interface CreateInvitationPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationPayload
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationPayload
     */
    organizationId: string;
    /**
     * 
     * @type {any}
     * @memberof CreateInvitationPayload
     */
    extra?: any;
}
/**
 * 
 * @export
 * @interface CustomerProfile
 */
export interface CustomerProfile {
    /**
     * 
     * @type {string}
     * @memberof CustomerProfile
     */
    type: CustomerProfile.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerProfile
     */
    userProfileId: string;
    /**
     * 
     * @type {StripeCustomerProfile}
     * @memberof CustomerProfile
     */
    stripe: StripeCustomerProfile;
    /**
     * 
     * @type {OfflineCustomerProfile}
     * @memberof CustomerProfile
     */
    offline: OfflineCustomerProfile;
}

/**
 * @export
 * @namespace CustomerProfile
 */
export namespace CustomerProfile {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        STRIPE = <any> 'STRIPE',
        SHOPIFY = <any> 'SHOPIFY',
        OFFLINE = <any> 'OFFLINE'
    }
}
/**
 * 
 * @export
 * @interface GPSUnit
 */
export interface GPSUnit {
    /**
     * 
     * @type {string}
     * @memberof GPSUnit
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GPSUnit
     */
    associatedUserProfileId: string;
    /**
     * 
     * @type {string}
     * @memberof GPSUnit
     */
    deviceName: string;
    /**
     * number of milliseconds since epoch
     * @type {number}
     * @memberof GPSUnit
     */
    lastUpdatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof GPSUnit
     */
    locationAccuracy: number;
    /**
     * 
     * @type {number}
     * @memberof GPSUnit
     */
    locationLatitude: number;
    /**
     * 
     * @type {number}
     * @memberof GPSUnit
     */
    locationLongitude: number;
    /**
     * 
     * @type {any}
     * @memberof GPSUnit
     */
    extra?: any;
}
/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    invitationId: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    organizationId: string;
    /**
     * number of milliseconds since epoch
     * @type {number}
     * @memberof Invitation
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    createdBy: string;
    /**
     * number of milliseconds since epoch
     * @type {number}
     * @memberof Invitation
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    status: Invitation.StatusEnum;
    /**
     * 
     * @type {any}
     * @memberof Invitation
     */
    extra?: any;
}

/**
 * @export
 * @namespace Invitation
 */
export namespace Invitation {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        PENDING = <any> 'PENDING',
        ACCEPTED = <any> 'ACCEPTED',
        DECLINED = <any> 'DECLINED'
    }
}
/**
 * 
 * @export
 * @interface InvitationPayload
 */
export interface InvitationPayload {
    /**
     * 
     * @type {string}
     * @memberof InvitationPayload
     */
    status: InvitationPayload.StatusEnum;
    /**
     * 
     * @type {any}
     * @memberof InvitationPayload
     */
    extra?: any;
}

/**
 * @export
 * @namespace InvitationPayload
 */
export namespace InvitationPayload {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        PENDING = <any> 'PENDING',
        ACCEPTED = <any> 'ACCEPTED',
        DECLINED = <any> 'DECLINED'
    }
}
/**
 * 
 * @export
 * @interface LatLng
 */
export interface LatLng {
    /**
     * 
     * @type {number}
     * @memberof LatLng
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof LatLng
     */
    longitude: number;
}
/**
 * 
 * @export
 * @interface ListOfActionToggles
 */
export interface ListOfActionToggles {
    /**
     * 
     * @type {Array<ActionToggle>}
     * @memberof ListOfActionToggles
     */
    records?: Array<ActionToggle>;
    /**
     * 
     * @type {number}
     * @memberof ListOfActionToggles
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfApiKeys
 */
export interface ListOfApiKeys {
    /**
     * 
     * @type {Array<ApiKey>}
     * @memberof ListOfApiKeys
     */
    records?: Array<ApiKey>;
    /**
     * 
     * @type {number}
     * @memberof ListOfApiKeys
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfClients
 */
export interface ListOfClients {
    /**
     * 
     * @type {Array<Client>}
     * @memberof ListOfClients
     */
    records?: Array<Client>;
    /**
     * 
     * @type {number}
     * @memberof ListOfClients
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfCustomerProfiles
 */
export interface ListOfCustomerProfiles {
    /**
     * 
     * @type {Array<CustomerProfile>}
     * @memberof ListOfCustomerProfiles
     */
    records?: Array<CustomerProfile>;
    /**
     * 
     * @type {number}
     * @memberof ListOfCustomerProfiles
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfInvitations
 */
export interface ListOfInvitations {
    /**
     * 
     * @type {Array<Invitation>}
     * @memberof ListOfInvitations
     */
    records?: Array<Invitation>;
    /**
     * 
     * @type {number}
     * @memberof ListOfInvitations
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfOrganizationUserProfiles
 */
export interface ListOfOrganizationUserProfiles {
    /**
     * 
     * @type {Array<OrganizationUserProfile>}
     * @memberof ListOfOrganizationUserProfiles
     */
    records?: Array<OrganizationUserProfile>;
    /**
     * 
     * @type {number}
     * @memberof ListOfOrganizationUserProfiles
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfOrganizations
 */
export interface ListOfOrganizations {
    /**
     * 
     * @type {Array<Organization>}
     * @memberof ListOfOrganizations
     */
    records?: Array<Organization>;
    /**
     * 
     * @type {number}
     * @memberof ListOfOrganizations
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfTripNotes
 */
export interface ListOfTripNotes {
    /**
     * 
     * @type {Array<TripNote>}
     * @memberof ListOfTripNotes
     */
    records?: Array<TripNote>;
    /**
     * 
     * @type {number}
     * @memberof ListOfTripNotes
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfTrips
 */
export interface ListOfTrips {
    /**
     * 
     * @type {Array<Trip>}
     * @memberof ListOfTrips
     */
    records?: Array<Trip>;
    /**
     * 
     * @type {number}
     * @memberof ListOfTrips
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ListOfUserProfiles
 */
export interface ListOfUserProfiles {
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof ListOfUserProfiles
     */
    records?: Array<UserProfile>;
    /**
     * 
     * @type {number}
     * @memberof ListOfUserProfiles
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface LoginPayload
 */
export interface LoginPayload {
    /**
     * 
     * @type {string}
     * @memberof LoginPayload
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginPayload
     */
    password: string;
}
/**
 * 
 * @export
 * @interface OfflineCustomerProfile
 */
export interface OfflineCustomerProfile {
    /**
     * 
     * @type {string}
     * @memberof OfflineCustomerProfile
     */
    notes: string;
}
/**
 * 
 * @export
 * @interface OptimizedRouteRequestPayload
 */
export interface OptimizedRouteRequestPayload {
    /**
     * 
     * @type {LatLng}
     * @memberof OptimizedRouteRequestPayload
     */
    startLocation: LatLng;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptimizedRouteRequestPayload
     */
    tripIds: Array<string>;
}
/**
 * 
 * @export
 * @interface OptimizedRouteResponse
 */
export interface OptimizedRouteResponse {
    /**
     * 
     * @type {number}
     * @memberof OptimizedRouteResponse
     */
    totalDistance: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptimizedRouteResponse
     */
    orderedTripIds: Array<string>;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    updatedAt: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    createdAt: number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    ownerUserProfileId: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    id: string;
    /**
     * 
     * @type {LatLng}
     * @memberof Organization
     */
    location: LatLng;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    hasActiveSubscription: boolean;
    /**
     * 
     * @type {any}
     * @memberof Organization
     */
    extra?: any;
}
/**
 * 
 * @export
 * @interface OrganizationCustomerProfile
 */
export interface OrganizationCustomerProfile {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustomerProfile
     */
    type: OrganizationCustomerProfile.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustomerProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustomerProfile
     */
    userProfileId: string;
    /**
     * 
     * @type {StripeCustomerProfile}
     * @memberof OrganizationCustomerProfile
     */
    stripe: StripeCustomerProfile;
    /**
     * 
     * @type {OfflineCustomerProfile}
     * @memberof OrganizationCustomerProfile
     */
    offline: OfflineCustomerProfile;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustomerProfile
     */
    organizationId: string;
}

/**
 * @export
 * @namespace OrganizationCustomerProfile
 */
export namespace OrganizationCustomerProfile {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        STRIPE = <any> 'STRIPE',
        SHOPIFY = <any> 'SHOPIFY',
        OFFLINE = <any> 'OFFLINE'
    }
}
/**
 * 
 * @export
 * @interface OrganizationQueryFilter
 */
export interface OrganizationQueryFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationQueryFilter
     */
    ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrganizationQueryFilter
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationQueryFilter
     */
    userProfileId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationQueryFilter
     */
    address?: string;
}
/**
 * 
 * @export
 * @interface OrganizationUserProfile
 */
export interface OrganizationUserProfile {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserProfile
     */
    userProfileId: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserProfile
     */
    role: OrganizationUserProfile.RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserProfile
     */
    status: OrganizationUserProfile.StatusEnum;
    /**
     * 
     * @type {any}
     * @memberof OrganizationUserProfile
     */
    extra?: any;
}

/**
 * @export
 * @namespace OrganizationUserProfile
 */
export namespace OrganizationUserProfile {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        ADMIN = <any> 'ADMIN',
        DRIVER = <any> 'DRIVER',
        ASSOCIATE = <any> 'ASSOCIATE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        UNVERIFIED = <any> 'UNVERIFIED',
        PENDING = <any> 'PENDING',
        ACTIVE = <any> 'ACTIVE',
        DISABLED = <any> 'DISABLED',
        DELETED = <any> 'DELETED'
    }
}
/**
 * 
 * @export
 * @interface RefreshTokenPayload
 */
export interface RefreshTokenPayload {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenPayload
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface RefreshTokenResponse
 */
export interface RefreshTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    expiresIn: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    tokenType: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    idToken: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface ScheduleDate
 */
export interface ScheduleDate {
    /**
     * 
     * @type {number}
     * @memberof ScheduleDate
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDate
     */
    month: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDate
     */
    date: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDate
     */
    hour: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDate
     */
    minute: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleDate
     */
    second: number;
}
/**
 * 
 * @export
 * @interface SetupCustomerProfileRequest
 */
export interface SetupCustomerProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof SetupCustomerProfileRequest
     */
    type: SetupCustomerProfileRequest.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SetupCustomerProfileRequest
     */
    userProfileId: string;
    /**
     * 
     * @type {boolean}
     * @memberof SetupCustomerProfileRequest
     */
    blockchainService?: boolean;
}

/**
 * @export
 * @namespace SetupCustomerProfileRequest
 */
export namespace SetupCustomerProfileRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        STRIPE = <any> 'STRIPE',
        SHOPIFY = <any> 'SHOPIFY',
        OFFLINE = <any> 'OFFLINE'
    }
}
/**
 * 
 * @export
 * @interface SetupCustomerProfileResponse
 */
export interface SetupCustomerProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof SetupCustomerProfileResponse
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface SortParam
 */
export interface SortParam {
    /**
     * 
     * @type {string}
     * @memberof SortParam
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof SortParam
     */
    order: SortParam.OrderEnum;
}

/**
 * @export
 * @namespace SortParam
 */
export namespace SortParam {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderEnum {
        Asc = <any> 'asc',
        Desc = <any> 'desc',
        ASC = <any> 'ASC',
        DESC = <any> 'DESC'
    }
}
/**
 * 
 * @export
 * @interface StripeCard
 */
export interface StripeCard {
    /**
     * 
     * @type {string}
     * @memberof StripeCard
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof StripeCard
     */
    last4: string;
    /**
     * 
     * @type {number}
     * @memberof StripeCard
     */
    expiryYear: number;
    /**
     * 
     * @type {number}
     * @memberof StripeCard
     */
    expiryMonth: number;
}
/**
 * 
 * @export
 * @interface StripeCustomerProfile
 */
export interface StripeCustomerProfile {
    /**
     * 
     * @type {string}
     * @memberof StripeCustomerProfile
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof StripeCustomerProfile
     */
    subscriptionId: string;
    /**
     * 
     * @type {StripeCard}
     * @memberof StripeCustomerProfile
     */
    card: StripeCard;
}
/**
 * 
 * @export
 * @interface SupportedApiVersions
 */
export interface SupportedApiVersions {
    /**
     * Clients using this version or lower will need to upgrade before making api calls
     * @type {ApiVersion}
     * @memberof SupportedApiVersions
     */
    minimumSupportedVersion: ApiVersion;
    /**
     * Clients using this version or higher are allowed to continue making api calls
     * @type {ApiVersion}
     * @memberof SupportedApiVersions
     */
    deprecatedVersion: ApiVersion;
    /**
     * 
     * @type {ApiVersion}
     * @memberof SupportedApiVersions
     */
    currentVersion: ApiVersion;
}
/**
 * 
 * @export
 * @interface TileImageResponse
 */
export interface TileImageResponse {
    /**
     * 
     * @type {string}
     * @memberof TileImageResponse
     */
    url: string;
}
/**
 * 
 * @export
 * @interface Trip
 */
export interface Trip {
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    gpsUnitId?: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    userProfileId?: string;
    /**
     * 
     * @type {TripClient}
     * @memberof Trip
     */
    client: TripClient;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    address?: string;
    /**
     * 
     * @type {LatLng}
     * @memberof Trip
     */
    location?: LatLng;
    /**
     * The scheduled date and time for this trip.
     * @type {ScheduleDate}
     * @memberof Trip
     */
    scheduledAt: ScheduleDate;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    tripStatus: Trip.TripStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    tripType: Trip.TripTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof Trip
     */
    extra?: any;
    /**
     * 
     * @type {TripOptions}
     * @memberof Trip
     */
    options?: TripOptions;
}

/**
 * @export
 * @namespace Trip
 */
export namespace Trip {
    /**
     * @export
     * @enum {string}
     */
    export enum TripStatusEnum {
        PENDING = <any> 'PENDING',
        SCHEDULED = <any> 'SCHEDULED',
        ONROUTE = <any> 'ON_ROUTE',
        COMPLETED = <any> 'COMPLETED',
        CANCELLED = <any> 'CANCELLED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TripTypeEnum {
        NONE = <any> 'NONE',
        DELIVERY = <any> 'DELIVERY'
    }
}
/**
 * 
 * @export
 * @interface TripClient
 */
export interface TripClient {
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    address?: string;
    /**
     * 
     * @type {LatLng}
     * @memberof TripClient
     */
    location?: LatLng;
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    homePhone: string;
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    cellPhone: string;
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    email: string;
    /**
     * 
     * @type {any}
     * @memberof TripClient
     */
    extra: any;
    /**
     * 
     * @type {ClientOptions}
     * @memberof TripClient
     */
    options: ClientOptions;
    /**
     * 
     * @type {string}
     * @memberof TripClient
     */
    notes: string;
}
/**
 * 
 * @export
 * @interface TripNote
 */
export interface TripNote {
    /**
     * 
     * @type {string}
     * @memberof TripNote
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TripNote
     */
    tripId: string;
    /**
     * 
     * @type {string}
     * @memberof TripNote
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof TripNote
     */
    createdBy: string;
    /**
     * 
     * @type {number}
     * @memberof TripNote
     */
    createdAt: number;
    /**
     * 
     * @type {boolean}
     * @memberof TripNote
     */
    isPrivate: boolean;
    /**
     * 
     * @type {TripNoteMetadata}
     * @memberof TripNote
     */
    metadata?: TripNoteMetadata;
    /**
     * 
     * @type {any}
     * @memberof TripNote
     */
    extra?: any;
}
/**
 * 
 * @export
 * @interface TripNoteMetadata
 */
export interface TripNoteMetadata {
    /**
     * 
     * @type {LatLng}
     * @memberof TripNoteMetadata
     */
    location: LatLng;
    /**
     * 
     * @type {any}
     * @memberof TripNoteMetadata
     */
    details: any;
}
/**
 * 
 * @export
 * @interface TripOptions
 */
export interface TripOptions {
    /**
     * 
     * @type {boolean}
     * @memberof TripOptions
     */
    enableNotifications: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TripOptions
     */
    requireProofOfDelivery: boolean;
}
/**
 * 
 * @export
 * @interface TripProofOfDelivery
 */
export interface TripProofOfDelivery {
    /**
     * 
     * @type {string}
     * @memberof TripProofOfDelivery
     */
    tripId: string;
    /**
     * 
     * @type {string}
     * @memberof TripProofOfDelivery
     */
    type: TripProofOfDelivery.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TripProofOfDelivery
     */
    recipientName?: string;
    /**
     * 
     * @type {string}
     * @memberof TripProofOfDelivery
     */
    photoUrl?: string;
    /**
     * 
     * @type {Blob}
     * @memberof TripProofOfDelivery
     */
    photofile?: Blob;
    /**
     * 
     * @type {string}
     * @memberof TripProofOfDelivery
     */
    createdBy?: string;
    /**
     * 
     * @type {number}
     * @memberof TripProofOfDelivery
     */
    createdAt?: number;
}

/**
 * @export
 * @namespace TripProofOfDelivery
 */
export namespace TripProofOfDelivery {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        NAME = <any> 'NAME',
        PHOTO = <any> 'PHOTO'
    }
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    id: string;
    /**
     * number of milliseconds since epoch
     * @type {number}
     * @memberof UserProfile
     */
    createdAt?: number;
    /**
     * number of milliseconds since epoch
     * @type {number}
     * @memberof UserProfile
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    updatedBy?: string;
    /**
     * 
     * @type {any}
     * @memberof UserProfile
     */
    extra?: any;
}
/**
 * 
 * @export
 * @interface UserProfileOrganization
 */
export interface UserProfileOrganization {
    /**
     * 
     * @type {string}
     * @memberof UserProfileOrganization
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileOrganization
     */
    role?: UserProfileOrganization.RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfileOrganization
     */
    status?: UserProfileOrganization.StatusEnum;
    /**
     * 
     * @type {any}
     * @memberof UserProfileOrganization
     */
    extra?: any;
}

/**
 * @export
 * @namespace UserProfileOrganization
 */
export namespace UserProfileOrganization {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        ADMIN = <any> 'ADMIN',
        DRIVER = <any> 'DRIVER',
        ASSOCIATE = <any> 'ASSOCIATE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        UNVERIFIED = <any> 'UNVERIFIED',
        PENDING = <any> 'PENDING',
        ACTIVE = <any> 'ACTIVE',
        DISABLED = <any> 'DISABLED',
        DELETED = <any> 'DELETED'
    }
}
/**
 * 
 * @export
 * @interface UserProfileQueryFilter
 */
export interface UserProfileQueryFilter {
    /**
     * 
     * @type {string}
     * @memberof UserProfileQueryFilter
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileQueryFilter
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileQueryFilter
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileQueryFilter
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileQueryFilter
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileQueryFilter
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserProfileQueryFilter
     */
    ids?: Array<string>;
}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create ApiKey
         * @param {CreateApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: CreateApiKeyRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createApiKey.');
            }
            const localVarPath = `/api/auth/api-key`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateApiKeyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete ApiKey
         * @param {string} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(keyId: string, options: any = {}): FetchArgs {
            // verify required parameter 'keyId' is not null or undefined
            if (keyId === null || keyId === undefined) {
                throw new RequiredError('keyId','Required parameter keyId was null or undefined when calling deleteApiKey.');
            }
            const localVarPath = `/api/auth/api-key/{keyId}`
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginPayload, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling login.');
            }
            const localVarPath = `/api/auth`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicAuthControllerRenderLoginPage(options: any = {}): FetchArgs {
            const localVarPath = `/api/auth`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Token
         * @param {RefreshTokenPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(body: RefreshTokenPayload, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling refreshToken.');
            }
            const localVarPath = `/api/auth/refresh-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RefreshTokenPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create ApiKey
         * @param {CreateApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: CreateApiKeyRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiKey> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).createApiKey(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete ApiKey
         * @param {string} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(keyId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).deleteApiKey(keyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthUser> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).login(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicAuthControllerRenderLoginPage(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).publicAuthControllerRenderLoginPage(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Refresh Token
         * @param {RefreshTokenPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(body: RefreshTokenPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RefreshTokenResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).refreshToken(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create ApiKey
         * @param {CreateApiKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKey(body: CreateApiKeyRequest, options?: any) {
            return AuthApiFp(configuration).createApiKey(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete ApiKey
         * @param {string} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKey(keyId: string, options?: any) {
            return AuthApiFp(configuration).deleteApiKey(keyId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Login
         * @param {LoginPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginPayload, options?: any) {
            return AuthApiFp(configuration).login(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicAuthControllerRenderLoginPage(options?: any) {
            return AuthApiFp(configuration).publicAuthControllerRenderLoginPage(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Refresh Token
         * @param {RefreshTokenPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(body: RefreshTokenPayload, options?: any) {
            return AuthApiFp(configuration).refreshToken(body, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Create ApiKey
     * @param {CreateApiKeyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createApiKey(body: CreateApiKeyRequest, options?: any) {
        return AuthApiFp(this.configuration).createApiKey(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete ApiKey
     * @param {string} keyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public deleteApiKey(keyId: string, options?: any) {
        return AuthApiFp(this.configuration).deleteApiKey(keyId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Login
     * @param {LoginPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(body: LoginPayload, options?: any) {
        return AuthApiFp(this.configuration).login(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public publicAuthControllerRenderLoginPage(options?: any) {
        return AuthApiFp(this.configuration).publicAuthControllerRenderLoginPage(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Refresh Token
     * @param {RefreshTokenPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(body: RefreshTokenPayload, options?: any) {
        return AuthApiFp(this.configuration).refreshToken(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ClientsApi - fetch parameter creator
 * @export
 */
export const ClientsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deprecated. See Ok Response for more information.
         * @summary listClients
         * @param {string} filter 
         * @param {string} [sort] 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(filter: string, sort?: string, range?: string, options: any = {}): FetchArgs {
            // verify required parameter 'filter' is not null or undefined
            if (filter === null || filter === undefined) {
                throw new RequiredError('filter','Required parameter filter was null or undefined when calling listClients.');
            }
            const localVarPath = `/api/clients`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Deprecated. See Ok Response for more information.
         * @summary listClients
         * @param {string} filter 
         * @param {string} [sort] 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(filter: string, sort?: string, range?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfClients> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).listClients(filter, sort, range, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Deprecated. See Ok Response for more information.
         * @summary listClients
         * @param {string} filter 
         * @param {string} [sort] 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(filter: string, sort?: string, range?: string, options?: any) {
            return ClientsApiFp(configuration).listClients(filter, sort, range, options)(fetch, basePath);
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * Deprecated. See Ok Response for more information.
     * @summary listClients
     * @param {string} filter 
     * @param {string} [sort] 
     * @param {string} [range] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public listClients(filter: string, sort?: string, range?: string, options?: any) {
        return ClientsApiFp(this.configuration).listClients(filter, sort, range, options)(this.fetch, this.basePath);
    }

}
/**
 * GpsunitsApi - fetch parameter creator
 * @export
 */
export const GpsunitsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createGpsUnit
         * @param {GPSUnit} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGpsUnit(body: GPSUnit, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createGpsUnit.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling createGpsUnit.');
            }
            const localVarPath = `/api/gpsunits/{userProfileId}/gpsunits`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GPSUnit" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveGpsUnit
         * @param {string} gpsUnitId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGpsUnit(gpsUnitId: string, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'gpsUnitId' is not null or undefined
            if (gpsUnitId === null || gpsUnitId === undefined) {
                throw new RequiredError('gpsUnitId','Required parameter gpsUnitId was null or undefined when calling retrieveGpsUnit.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling retrieveGpsUnit.');
            }
            const localVarPath = `/api/gpsunits/{userProfileId}/gpsunits/{gpsUnitId}`
                .replace(`{${"gpsUnitId"}}`, encodeURIComponent(String(gpsUnitId)))
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateGpsUnit
         * @param {GPSUnit} body 
         * @param {string} gpsUnitId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGpsUnit(body: GPSUnit, gpsUnitId: string, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateGpsUnit.');
            }
            // verify required parameter 'gpsUnitId' is not null or undefined
            if (gpsUnitId === null || gpsUnitId === undefined) {
                throw new RequiredError('gpsUnitId','Required parameter gpsUnitId was null or undefined when calling updateGpsUnit.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling updateGpsUnit.');
            }
            const localVarPath = `/api/gpsunits/{userProfileId}/gpsunits/{gpsUnitId}`
                .replace(`{${"gpsUnitId"}}`, encodeURIComponent(String(gpsUnitId)))
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GPSUnit" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GpsunitsApi - functional programming interface
 * @export
 */
export const GpsunitsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createGpsUnit
         * @param {GPSUnit} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGpsUnit(body: GPSUnit, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPSUnit> {
            const localVarFetchArgs = GpsunitsApiFetchParamCreator(configuration).createGpsUnit(body, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveGpsUnit
         * @param {string} gpsUnitId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGpsUnit(gpsUnitId: string, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPSUnit> {
            const localVarFetchArgs = GpsunitsApiFetchParamCreator(configuration).retrieveGpsUnit(gpsUnitId, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateGpsUnit
         * @param {GPSUnit} body 
         * @param {string} gpsUnitId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGpsUnit(body: GPSUnit, gpsUnitId: string, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPSUnit> {
            const localVarFetchArgs = GpsunitsApiFetchParamCreator(configuration).updateGpsUnit(body, gpsUnitId, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GpsunitsApi - factory interface
 * @export
 */
export const GpsunitsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createGpsUnit
         * @param {GPSUnit} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGpsUnit(body: GPSUnit, userProfileId: string, options?: any) {
            return GpsunitsApiFp(configuration).createGpsUnit(body, userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveGpsUnit
         * @param {string} gpsUnitId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGpsUnit(gpsUnitId: string, userProfileId: string, options?: any) {
            return GpsunitsApiFp(configuration).retrieveGpsUnit(gpsUnitId, userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateGpsUnit
         * @param {GPSUnit} body 
         * @param {string} gpsUnitId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGpsUnit(body: GPSUnit, gpsUnitId: string, userProfileId: string, options?: any) {
            return GpsunitsApiFp(configuration).updateGpsUnit(body, gpsUnitId, userProfileId, options)(fetch, basePath);
        },
    };
};

/**
 * GpsunitsApi - object-oriented interface
 * @export
 * @class GpsunitsApi
 * @extends {BaseAPI}
 */
export class GpsunitsApi extends BaseAPI {
    /**
     * 
     * @summary createGpsUnit
     * @param {GPSUnit} body 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GpsunitsApi
     */
    public createGpsUnit(body: GPSUnit, userProfileId: string, options?: any) {
        return GpsunitsApiFp(this.configuration).createGpsUnit(body, userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveGpsUnit
     * @param {string} gpsUnitId 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GpsunitsApi
     */
    public retrieveGpsUnit(gpsUnitId: string, userProfileId: string, options?: any) {
        return GpsunitsApiFp(this.configuration).retrieveGpsUnit(gpsUnitId, userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateGpsUnit
     * @param {GPSUnit} body 
     * @param {string} gpsUnitId 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GpsunitsApi
     */
    public updateGpsUnit(body: GPSUnit, gpsUnitId: string, userProfileId: string, options?: any) {
        return GpsunitsApiFp(this.configuration).updateGpsUnit(body, gpsUnitId, userProfileId, options)(this.fetch, this.basePath);
    }

}
/**
 * InvitationsApi - fetch parameter creator
 * @export
 */
export const InvitationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createInvitation
         * @param {CreateInvitationPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation(body: CreateInvitationPayload, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createInvitation.');
            }
            const localVarPath = `/api/invitations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateInvitationPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling listInvitations.');
            }
            const localVarPath = `/api/organizations/{organizationId}/invitations`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitationsApi - functional programming interface
 * @export
 */
export const InvitationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createInvitation
         * @param {CreateInvitationPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation(body: CreateInvitationPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invitation> {
            const localVarFetchArgs = InvitationsApiFetchParamCreator(configuration).createInvitation(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfInvitations> {
            const localVarFetchArgs = InvitationsApiFetchParamCreator(configuration).listInvitations(organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InvitationsApi - factory interface
 * @export
 */
export const InvitationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createInvitation
         * @param {CreateInvitationPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation(body: CreateInvitationPayload, options?: any) {
            return InvitationsApiFp(configuration).createInvitation(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(organizationId: string, options?: any) {
            return InvitationsApiFp(configuration).listInvitations(organizationId, options)(fetch, basePath);
        },
    };
};

/**
 * InvitationsApi - object-oriented interface
 * @export
 * @class InvitationsApi
 * @extends {BaseAPI}
 */
export class InvitationsApi extends BaseAPI {
    /**
     * 
     * @summary createInvitation
     * @param {CreateInvitationPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public createInvitation(body: CreateInvitationPayload, options?: any) {
        return InvitationsApiFp(this.configuration).createInvitation(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listInvitations
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public listInvitations(organizationId: string, options?: any) {
        return InvitationsApiFp(this.configuration).listInvitations(organizationId, options)(this.fetch, this.basePath);
    }

}
/**
 * NavigationApi - fetch parameter creator
 * @export
 */
export const NavigationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getTileImage
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {number} height 
         * @param {number} width 
         * @param {number} zoom 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTileImage(latitude: number, longitude: number, height: number, width: number, zoom: number, organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'latitude' is not null or undefined
            if (latitude === null || latitude === undefined) {
                throw new RequiredError('latitude','Required parameter latitude was null or undefined when calling getTileImage.');
            }
            // verify required parameter 'longitude' is not null or undefined
            if (longitude === null || longitude === undefined) {
                throw new RequiredError('longitude','Required parameter longitude was null or undefined when calling getTileImage.');
            }
            // verify required parameter 'height' is not null or undefined
            if (height === null || height === undefined) {
                throw new RequiredError('height','Required parameter height was null or undefined when calling getTileImage.');
            }
            // verify required parameter 'width' is not null or undefined
            if (width === null || width === undefined) {
                throw new RequiredError('width','Required parameter width was null or undefined when calling getTileImage.');
            }
            // verify required parameter 'zoom' is not null or undefined
            if (zoom === null || zoom === undefined) {
                throw new RequiredError('zoom','Required parameter zoom was null or undefined when calling getTileImage.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling getTileImage.');
            }
            const localVarPath = `/api/navigation/{organizationId}/tile-image`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (zoom !== undefined) {
                localVarQueryParameter['zoom'] = zoom;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary optimizeRoute
         * @param {OptimizedRouteRequestPayload} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optimizeRoute(body: OptimizedRouteRequestPayload, organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling optimizeRoute.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling optimizeRoute.');
            }
            const localVarPath = `/api/navigation/{organizationId}/optimize-route`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OptimizedRouteRequestPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NavigationApi - functional programming interface
 * @export
 */
export const NavigationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getTileImage
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {number} height 
         * @param {number} width 
         * @param {number} zoom 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTileImage(latitude: number, longitude: number, height: number, width: number, zoom: number, organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TileImageResponse> {
            const localVarFetchArgs = NavigationApiFetchParamCreator(configuration).getTileImage(latitude, longitude, height, width, zoom, organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary optimizeRoute
         * @param {OptimizedRouteRequestPayload} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optimizeRoute(body: OptimizedRouteRequestPayload, organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OptimizedRouteResponse> {
            const localVarFetchArgs = NavigationApiFetchParamCreator(configuration).optimizeRoute(body, organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NavigationApi - factory interface
 * @export
 */
export const NavigationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getTileImage
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {number} height 
         * @param {number} width 
         * @param {number} zoom 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTileImage(latitude: number, longitude: number, height: number, width: number, zoom: number, organizationId: string, options?: any) {
            return NavigationApiFp(configuration).getTileImage(latitude, longitude, height, width, zoom, organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary optimizeRoute
         * @param {OptimizedRouteRequestPayload} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optimizeRoute(body: OptimizedRouteRequestPayload, organizationId: string, options?: any) {
            return NavigationApiFp(configuration).optimizeRoute(body, organizationId, options)(fetch, basePath);
        },
    };
};

/**
 * NavigationApi - object-oriented interface
 * @export
 * @class NavigationApi
 * @extends {BaseAPI}
 */
export class NavigationApi extends BaseAPI {
    /**
     * 
     * @summary getTileImage
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {number} height 
     * @param {number} width 
     * @param {number} zoom 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public getTileImage(latitude: number, longitude: number, height: number, width: number, zoom: number, organizationId: string, options?: any) {
        return NavigationApiFp(this.configuration).getTileImage(latitude, longitude, height, width, zoom, organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary optimizeRoute
     * @param {OptimizedRouteRequestPayload} body 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public optimizeRoute(body: OptimizedRouteRequestPayload, organizationId: string, options?: any) {
        return NavigationApiFp(this.configuration).optimizeRoute(body, organizationId, options)(this.fetch, this.basePath);
    }

}
/**
 * OrganizationsApi - fetch parameter creator
 * @export
 */
export const OrganizationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary attachCustomerProfile
         * @param {CustomerProfile} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachCustomerProfile(body: CustomerProfile, organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling attachCustomerProfile.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling attachCustomerProfile.');
            }
            const localVarPath = `/api/organizations/{organizationId}/customer-profiles`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createClient
         * @param {Client} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient(body: Client, organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createClient.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling createClient.');
            }
            const localVarPath = `/api/organizations/{organizationId}/clients`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Client" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrganization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(body: Organization, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrganization.');
            }
            const localVarPath = `/api/organizations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Organization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCustomerProfile
         * @param {CustomerProfile} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProfile(body: CustomerProfile, organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteCustomerProfile.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling deleteCustomerProfile.');
            }
            const localVarPath = `/api/organizations/{organizationId}/customer-profiles`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listClients
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling listClients.');
            }
            const localVarPath = `/api/organizations/{organizationId}/clients`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling listInvitations.');
            }
            const localVarPath = `/api/organizations/{organizationId}/invitations`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listOrganizationUserProfiles
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationUserProfiles(organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling listOrganizationUserProfiles.');
            }
            const localVarPath = `/api/organizations/{organizationId}/userprofiles`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listOrganizations
         * @param {string} [sort] 
         * @param {string} [range] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations(sort?: string, range?: string, filter?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/organizations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listTrips
         * @param {string} organizationId 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(organizationId: string, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling listTrips.');
            }
            const localVarPath = `/api/organizations/{organizationId}/trips`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveClient
         * @param {string} clientId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveClient(clientId: string, organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling retrieveClient.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling retrieveClient.');
            }
            const localVarPath = `/api/organizations/{organizationId}/clients/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveCustomerProfile
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCustomerProfile(organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling retrieveCustomerProfile.');
            }
            const localVarPath = `/api/organizations/{organizationId}/customer-profiles`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveOrganization
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOrganization(organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling retrieveOrganization.');
            }
            const localVarPath = `/api/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateClient
         * @param {Client} body 
         * @param {string} clientId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(body: Client, clientId: string, organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateClient.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling updateClient.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling updateClient.');
            }
            const localVarPath = `/api/organizations/{organizationId}/clients/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Client" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateOrganization
         * @param {Organization} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: Organization, organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrganization.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling updateOrganization.');
            }
            const localVarPath = `/api/organizations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Organization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateOrganizationUserProfile
         * @param {OrganizationUserProfile} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUserProfile(body: OrganizationUserProfile, organizationId: string, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrganizationUserProfile.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling updateOrganizationUserProfile.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling updateOrganizationUserProfile.');
            }
            const localVarPath = `/api/organizations/{organizationId}/userprofiles/{userProfileId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrganizationUserProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary attachCustomerProfile
         * @param {CustomerProfile} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachCustomerProfile(body: CustomerProfile, organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrganizationCustomerProfile> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).attachCustomerProfile(body, organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createClient
         * @param {Client} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient(body: Client, organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Client> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).createClient(body, organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrganization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(body: Organization, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).createOrganization(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteCustomerProfile
         * @param {CustomerProfile} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProfile(body: CustomerProfile, organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).deleteCustomerProfile(body, organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listClients
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfClients> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).listClients(organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfInvitations> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).listInvitations(organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listOrganizationUserProfiles
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationUserProfiles(organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfOrganizationUserProfiles> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).listOrganizationUserProfiles(organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listOrganizations
         * @param {string} [sort] 
         * @param {string} [range] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations(sort?: string, range?: string, filter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfOrganizations> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).listOrganizations(sort, range, filter, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listTrips
         * @param {string} organizationId 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(organizationId: string, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfTrips> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).listTrips(organizationId, clientId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveClient
         * @param {string} clientId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveClient(clientId: string, organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Client> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).retrieveClient(clientId, organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveCustomerProfile
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCustomerProfile(organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrganizationCustomerProfile> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).retrieveCustomerProfile(organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveOrganization
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOrganization(organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).retrieveOrganization(organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateClient
         * @param {Client} body 
         * @param {string} clientId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(body: Client, clientId: string, organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Client> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).updateClient(body, clientId, organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateOrganization
         * @param {Organization} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: Organization, organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).updateOrganization(body, organizationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateOrganizationUserProfile
         * @param {OrganizationUserProfile} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUserProfile(body: OrganizationUserProfile, organizationId: string, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrganizationUserProfile> {
            const localVarFetchArgs = OrganizationsApiFetchParamCreator(configuration).updateOrganizationUserProfile(body, organizationId, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary attachCustomerProfile
         * @param {CustomerProfile} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachCustomerProfile(body: CustomerProfile, organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).attachCustomerProfile(body, organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createClient
         * @param {Client} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient(body: Client, organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).createClient(body, organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createOrganization
         * @param {Organization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(body: Organization, options?: any) {
            return OrganizationsApiFp(configuration).createOrganization(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteCustomerProfile
         * @param {CustomerProfile} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProfile(body: CustomerProfile, organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).deleteCustomerProfile(body, organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listClients
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients(organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).listClients(organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).listInvitations(organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listOrganizationUserProfiles
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationUserProfiles(organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).listOrganizationUserProfiles(organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listOrganizations
         * @param {string} [sort] 
         * @param {string} [range] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations(sort?: string, range?: string, filter?: string, options?: any) {
            return OrganizationsApiFp(configuration).listOrganizations(sort, range, filter, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listTrips
         * @param {string} organizationId 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(organizationId: string, clientId?: string, options?: any) {
            return OrganizationsApiFp(configuration).listTrips(organizationId, clientId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveClient
         * @param {string} clientId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveClient(clientId: string, organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).retrieveClient(clientId, organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveCustomerProfile
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCustomerProfile(organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).retrieveCustomerProfile(organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveOrganization
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveOrganization(organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).retrieveOrganization(organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateClient
         * @param {Client} body 
         * @param {string} clientId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(body: Client, clientId: string, organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).updateClient(body, clientId, organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateOrganization
         * @param {Organization} body 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(body: Organization, organizationId: string, options?: any) {
            return OrganizationsApiFp(configuration).updateOrganization(body, organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateOrganizationUserProfile
         * @param {OrganizationUserProfile} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUserProfile(body: OrganizationUserProfile, organizationId: string, userProfileId: string, options?: any) {
            return OrganizationsApiFp(configuration).updateOrganizationUserProfile(body, organizationId, userProfileId, options)(fetch, basePath);
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary attachCustomerProfile
     * @param {CustomerProfile} body 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public attachCustomerProfile(body: CustomerProfile, organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).attachCustomerProfile(body, organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createClient
     * @param {Client} body 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createClient(body: Client, organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).createClient(body, organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createOrganization
     * @param {Organization} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createOrganization(body: Organization, options?: any) {
        return OrganizationsApiFp(this.configuration).createOrganization(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteCustomerProfile
     * @param {CustomerProfile} body 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteCustomerProfile(body: CustomerProfile, organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).deleteCustomerProfile(body, organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listClients
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public listClients(organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).listClients(organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listInvitations
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public listInvitations(organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).listInvitations(organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listOrganizationUserProfiles
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public listOrganizationUserProfiles(organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).listOrganizationUserProfiles(organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listOrganizations
     * @param {string} [sort] 
     * @param {string} [range] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public listOrganizations(sort?: string, range?: string, filter?: string, options?: any) {
        return OrganizationsApiFp(this.configuration).listOrganizations(sort, range, filter, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listTrips
     * @param {string} organizationId 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public listTrips(organizationId: string, clientId?: string, options?: any) {
        return OrganizationsApiFp(this.configuration).listTrips(organizationId, clientId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveClient
     * @param {string} clientId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public retrieveClient(clientId: string, organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).retrieveClient(clientId, organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveCustomerProfile
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public retrieveCustomerProfile(organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).retrieveCustomerProfile(organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveOrganization
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public retrieveOrganization(organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).retrieveOrganization(organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateClient
     * @param {Client} body 
     * @param {string} clientId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateClient(body: Client, clientId: string, organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).updateClient(body, clientId, organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateOrganization
     * @param {Organization} body 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganization(body: Organization, organizationId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).updateOrganization(body, organizationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateOrganizationUserProfile
     * @param {OrganizationUserProfile} body 
     * @param {string} organizationId 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganizationUserProfile(body: OrganizationUserProfile, organizationId: string, userProfileId: string, options?: any) {
        return OrganizationsApiFp(this.configuration).updateOrganizationUserProfile(body, organizationId, userProfileId, options)(this.fetch, this.basePath);
    }

}
/**
 * ServicestatusApi - fetch parameter creator
 * @export
 */
export const ServicestatusApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieveServiceStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceStatus(options: any = {}): FetchArgs {
            const localVarPath = `/api/servicestatus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveSupportedApiVersions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSupportedApiVersions(options: any = {}): FetchArgs {
            const localVarPath = `/api/servicestatus/versions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicestatusApi - functional programming interface
 * @export
 */
export const ServicestatusApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieveServiceStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceStatus(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfActionToggles> {
            const localVarFetchArgs = ServicestatusApiFetchParamCreator(configuration).retrieveServiceStatus(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveSupportedApiVersions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSupportedApiVersions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupportedApiVersions> {
            const localVarFetchArgs = ServicestatusApiFetchParamCreator(configuration).retrieveSupportedApiVersions(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ServicestatusApi - factory interface
 * @export
 */
export const ServicestatusApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary retrieveServiceStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveServiceStatus(options?: any) {
            return ServicestatusApiFp(configuration).retrieveServiceStatus(options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveSupportedApiVersions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSupportedApiVersions(options?: any) {
            return ServicestatusApiFp(configuration).retrieveSupportedApiVersions(options)(fetch, basePath);
        },
    };
};

/**
 * ServicestatusApi - object-oriented interface
 * @export
 * @class ServicestatusApi
 * @extends {BaseAPI}
 */
export class ServicestatusApi extends BaseAPI {
    /**
     * 
     * @summary retrieveServiceStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicestatusApi
     */
    public retrieveServiceStatus(options?: any) {
        return ServicestatusApiFp(this.configuration).retrieveServiceStatus(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveSupportedApiVersions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicestatusApi
     */
    public retrieveSupportedApiVersions(options?: any) {
        return ServicestatusApiFp(this.configuration).retrieveSupportedApiVersions(options)(this.fetch, this.basePath);
    }

}
/**
 * TripsApi - fetch parameter creator
 * @export
 */
export const TripsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createTrip
         * @param {Trip} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrip(body: Trip, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTrip.');
            }
            const localVarPath = `/api/trips`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Trip" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createTripNote
         * @param {TripNote} body 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTripNote(body: TripNote, tripId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTripNote.');
            }
            // verify required parameter 'tripId' is not null or undefined
            if (tripId === null || tripId === undefined) {
                throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling createTripNote.');
            }
            const localVarPath = `/api/trips/{tripId}/notes`
                .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TripNote" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteTripNote
         * @param {string} tripNoteId 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTripNote(tripNoteId: string, tripId: string, options: any = {}): FetchArgs {
            // verify required parameter 'tripNoteId' is not null or undefined
            if (tripNoteId === null || tripNoteId === undefined) {
                throw new RequiredError('tripNoteId','Required parameter tripNoteId was null or undefined when calling deleteTripNote.');
            }
            // verify required parameter 'tripId' is not null or undefined
            if (tripId === null || tripId === undefined) {
                throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling deleteTripNote.');
            }
            const localVarPath = `/api/trips/{tripId}/notes/{tripNoteId}`
                .replace(`{${"tripNoteId"}}`, encodeURIComponent(String(tripNoteId)))
                .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listTripNotes
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTripNotes(tripId: string, options: any = {}): FetchArgs {
            // verify required parameter 'tripId' is not null or undefined
            if (tripId === null || tripId === undefined) {
                throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling listTripNotes.');
            }
            const localVarPath = `/api/trips/{tripId}/notes`
                .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 'listTrips' operation is deprecated. Please use OrganizationsApi instead.
         * @summary listTrips
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(organizationIds?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/trips`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (organizationIds) {
                localVarQueryParameter['organizationIds'] = organizationIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveProofOfDelivery
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveProofOfDelivery(tripId: string, options: any = {}): FetchArgs {
            // verify required parameter 'tripId' is not null or undefined
            if (tripId === null || tripId === undefined) {
                throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling retrieveProofOfDelivery.');
            }
            const localVarPath = `/api/trips/{tripId}/proof-of-delivery`
                .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveTrip
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTrip(tripId: string, options: any = {}): FetchArgs {
            // verify required parameter 'tripId' is not null or undefined
            if (tripId === null || tripId === undefined) {
                throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling retrieveTrip.');
            }
            const localVarPath = `/api/trips/{tripId}`
                .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveTripStatus
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTripStatus(tripId: string, options: any = {}): FetchArgs {
            // verify required parameter 'tripId' is not null or undefined
            if (tripId === null || tripId === undefined) {
                throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling retrieveTripStatus.');
            }
            const localVarPath = `/api/trips/{tripId}/status`
                .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateTrip
         * @param {Trip} body 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrip(body: Trip, tripId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTrip.');
            }
            // verify required parameter 'tripId' is not null or undefined
            if (tripId === null || tripId === undefined) {
                throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling updateTrip.');
            }
            const localVarPath = `/api/trips/{tripId}`
                .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Trip" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadProofOfDelivery
         * @param {string} [tripId] 
         * @param {string} [type] 
         * @param {string} [recipientName] 
         * @param {string} [photoUrl] 
         * @param {Blob} [photofile] 
         * @param {string} [createdBy] 
         * @param {number} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProofOfDelivery(tripId?: string, type?: string, recipientName?: string, photoUrl?: string, photofile?: Blob, createdBy?: string, createdAt?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/trips/proof-of-delivery`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (tripId !== undefined) {
                localVarFormParams.set('tripId', tripId as any);
            }

            if (type !== undefined) {
                localVarFormParams.set('type', type as any);
            }

            if (recipientName !== undefined) {
                localVarFormParams.set('recipientName', recipientName as any);
            }

            if (photoUrl !== undefined) {
                localVarFormParams.set('photoUrl', photoUrl as any);
            }

            if (photofile !== undefined) {
                localVarFormParams.set('photofile', photofile as any);
            }

            if (createdBy !== undefined) {
                localVarFormParams.set('createdBy', createdBy as any);
            }

            if (createdAt !== undefined) {
                localVarFormParams.set('createdAt', createdAt as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TripsApi - functional programming interface
 * @export
 */
export const TripsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createTrip
         * @param {Trip} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrip(body: Trip, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Trip> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).createTrip(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createTripNote
         * @param {TripNote} body 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTripNote(body: TripNote, tripId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfTripNotes> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).createTripNote(body, tripId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteTripNote
         * @param {string} tripNoteId 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTripNote(tripNoteId: string, tripId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).deleteTripNote(tripNoteId, tripId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listTripNotes
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTripNotes(tripId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfTripNotes> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).listTripNotes(tripId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 'listTrips' operation is deprecated. Please use OrganizationsApi instead.
         * @summary listTrips
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(organizationIds?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfTrips> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).listTrips(organizationIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveProofOfDelivery
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveProofOfDelivery(tripId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TripProofOfDelivery> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).retrieveProofOfDelivery(tripId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveTrip
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTrip(tripId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Trip> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).retrieveTrip(tripId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveTripStatus
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTripStatus(tripId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Trip> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).retrieveTripStatus(tripId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateTrip
         * @param {Trip} body 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrip(body: Trip, tripId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Trip> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).updateTrip(body, tripId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadProofOfDelivery
         * @param {string} [tripId] 
         * @param {string} [type] 
         * @param {string} [recipientName] 
         * @param {string} [photoUrl] 
         * @param {Blob} [photofile] 
         * @param {string} [createdBy] 
         * @param {number} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProofOfDelivery(tripId?: string, type?: string, recipientName?: string, photoUrl?: string, photofile?: Blob, createdBy?: string, createdAt?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TripProofOfDelivery> {
            const localVarFetchArgs = TripsApiFetchParamCreator(configuration).uploadProofOfDelivery(tripId, type, recipientName, photoUrl, photofile, createdBy, createdAt, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TripsApi - factory interface
 * @export
 */
export const TripsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createTrip
         * @param {Trip} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrip(body: Trip, options?: any) {
            return TripsApiFp(configuration).createTrip(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createTripNote
         * @param {TripNote} body 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTripNote(body: TripNote, tripId: string, options?: any) {
            return TripsApiFp(configuration).createTripNote(body, tripId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteTripNote
         * @param {string} tripNoteId 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTripNote(tripNoteId: string, tripId: string, options?: any) {
            return TripsApiFp(configuration).deleteTripNote(tripNoteId, tripId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listTripNotes
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTripNotes(tripId: string, options?: any) {
            return TripsApiFp(configuration).listTripNotes(tripId, options)(fetch, basePath);
        },
        /**
         * 'listTrips' operation is deprecated. Please use OrganizationsApi instead.
         * @summary listTrips
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(organizationIds?: Array<string>, options?: any) {
            return TripsApiFp(configuration).listTrips(organizationIds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveProofOfDelivery
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveProofOfDelivery(tripId: string, options?: any) {
            return TripsApiFp(configuration).retrieveProofOfDelivery(tripId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveTrip
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTrip(tripId: string, options?: any) {
            return TripsApiFp(configuration).retrieveTrip(tripId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveTripStatus
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTripStatus(tripId: string, options?: any) {
            return TripsApiFp(configuration).retrieveTripStatus(tripId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateTrip
         * @param {Trip} body 
         * @param {string} tripId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrip(body: Trip, tripId: string, options?: any) {
            return TripsApiFp(configuration).updateTrip(body, tripId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadProofOfDelivery
         * @param {string} [tripId] 
         * @param {string} [type] 
         * @param {string} [recipientName] 
         * @param {string} [photoUrl] 
         * @param {Blob} [photofile] 
         * @param {string} [createdBy] 
         * @param {number} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProofOfDelivery(tripId?: string, type?: string, recipientName?: string, photoUrl?: string, photofile?: Blob, createdBy?: string, createdAt?: number, options?: any) {
            return TripsApiFp(configuration).uploadProofOfDelivery(tripId, type, recipientName, photoUrl, photofile, createdBy, createdAt, options)(fetch, basePath);
        },
    };
};

/**
 * TripsApi - object-oriented interface
 * @export
 * @class TripsApi
 * @extends {BaseAPI}
 */
export class TripsApi extends BaseAPI {
    /**
     * 
     * @summary createTrip
     * @param {Trip} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public createTrip(body: Trip, options?: any) {
        return TripsApiFp(this.configuration).createTrip(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createTripNote
     * @param {TripNote} body 
     * @param {string} tripId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public createTripNote(body: TripNote, tripId: string, options?: any) {
        return TripsApiFp(this.configuration).createTripNote(body, tripId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteTripNote
     * @param {string} tripNoteId 
     * @param {string} tripId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public deleteTripNote(tripNoteId: string, tripId: string, options?: any) {
        return TripsApiFp(this.configuration).deleteTripNote(tripNoteId, tripId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listTripNotes
     * @param {string} tripId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public listTripNotes(tripId: string, options?: any) {
        return TripsApiFp(this.configuration).listTripNotes(tripId, options)(this.fetch, this.basePath);
    }

    /**
     * 'listTrips' operation is deprecated. Please use OrganizationsApi instead.
     * @summary listTrips
     * @param {Array<string>} [organizationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public listTrips(organizationIds?: Array<string>, options?: any) {
        return TripsApiFp(this.configuration).listTrips(organizationIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveProofOfDelivery
     * @param {string} tripId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public retrieveProofOfDelivery(tripId: string, options?: any) {
        return TripsApiFp(this.configuration).retrieveProofOfDelivery(tripId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveTrip
     * @param {string} tripId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public retrieveTrip(tripId: string, options?: any) {
        return TripsApiFp(this.configuration).retrieveTrip(tripId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveTripStatus
     * @param {string} tripId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public retrieveTripStatus(tripId: string, options?: any) {
        return TripsApiFp(this.configuration).retrieveTripStatus(tripId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateTrip
     * @param {Trip} body 
     * @param {string} tripId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public updateTrip(body: Trip, tripId: string, options?: any) {
        return TripsApiFp(this.configuration).updateTrip(body, tripId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadProofOfDelivery
     * @param {string} [tripId] 
     * @param {string} [type] 
     * @param {string} [recipientName] 
     * @param {string} [photoUrl] 
     * @param {Blob} [photofile] 
     * @param {string} [createdBy] 
     * @param {number} [createdAt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripsApi
     */
    public uploadProofOfDelivery(tripId?: string, type?: string, recipientName?: string, photoUrl?: string, photofile?: Blob, createdBy?: string, createdAt?: number, options?: any) {
        return TripsApiFp(this.configuration).uploadProofOfDelivery(tripId, type, recipientName, photoUrl, photofile, createdBy, createdAt, options)(this.fetch, this.basePath);
    }

}
/**
 * UserprofilesApi - fetch parameter creator
 * @export
 */
export const UserprofilesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createUserProfile
         * @param {UserProfile} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfile(body: UserProfile, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createUserProfile.');
            }
            const localVarPath = `/api/userprofiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCustomerProfile
         * @param {string} customerProfileId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProfile(customerProfileId: string, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'customerProfileId' is not null or undefined
            if (customerProfileId === null || customerProfileId === undefined) {
                throw new RequiredError('customerProfileId','Required parameter customerProfileId was null or undefined when calling deleteCustomerProfile.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling deleteCustomerProfile.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/customer-profiles/{customerProfileId}`
                .replace(`{${"customerProfileId"}}`, encodeURIComponent(String(customerProfileId)))
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteInvitation
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation(organizationId: string, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling deleteInvitation.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling deleteInvitation.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/invitations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUserProfile
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfile(userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling deleteUserProfile.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listApiKeys
         * @param {string} userProfileId 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApiKeys(userProfileId: string, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling listApiKeys.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/api-keys`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listCustomerProfiles
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerProfiles(userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling listCustomerProfiles.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/customer-profiles`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling listInvitations.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/invitations`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listTrips
         * @param {string} userProfileId 
         * @param {string} [tripType] 
         * @param {string} [tripStatus] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(userProfileId: string, tripType?: string, tripStatus?: string, endDate?: string, startDate?: string, options: any = {}): FetchArgs {
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling listTrips.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/trips`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (tripType !== undefined) {
                localVarQueryParameter['tripType'] = tripType;
            }

            if (tripStatus !== undefined) {
                localVarQueryParameter['tripStatus'] = tripStatus;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listUserProfileOrganizations
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserProfileOrganizations(userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling listUserProfileOrganizations.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/organizations`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 'listUserProfiles' is deprecated. Please use 'OrganizationsApi.listUserProfiles' instead.
         * @summary listUserProfiles will be replaced by queryUserProfiles
         * @param {string} filter 
         * @param {string} [range] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserProfiles(filter: string, range?: string, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'filter' is not null or undefined
            if (filter === null || filter === undefined) {
                throw new RequiredError('filter','Required parameter filter was null or undefined when calling listUserProfiles.');
            }
            const localVarPath = `/api/userprofiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveInvitation
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInvitation(organizationId: string, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling retrieveInvitation.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling retrieveInvitation.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/invitations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveUserProfile
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUserProfile(userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling retrieveUserProfile.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setupCustomerProfile
         * @param {SetupCustomerProfileRequest} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupCustomerProfile(body: SetupCustomerProfileRequest, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setupCustomerProfile.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling setupCustomerProfile.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/customer-profiles`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SetupCustomerProfileRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateInvitation
         * @param {InvitationPayload} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvitation(body: InvitationPayload, organizationId: string, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateInvitation.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling updateInvitation.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling updateInvitation.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/invitations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvitationPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {UserProfile} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(body: UserProfile, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateUserProfile.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling updateUserProfile.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateUserProfileOrganization
         * @param {UserProfileOrganization} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileOrganization(body: UserProfileOrganization, organizationId: string, userProfileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateUserProfileOrganization.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling updateUserProfileOrganization.');
            }
            // verify required parameter 'userProfileId' is not null or undefined
            if (userProfileId === null || userProfileId === undefined) {
                throw new RequiredError('userProfileId','Required parameter userProfileId was null or undefined when calling updateUserProfileOrganization.');
            }
            const localVarPath = `/api/userprofiles/{userProfileId}/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-API-KEY")
					: configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }

            // authentication ID_TOKEN required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserProfileOrganization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserprofilesApi - functional programming interface
 * @export
 */
export const UserprofilesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createUserProfile
         * @param {UserProfile} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfile(body: UserProfile, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserProfile> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).createUserProfile(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteCustomerProfile
         * @param {string} customerProfileId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProfile(customerProfileId: string, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).deleteCustomerProfile(customerProfileId, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteInvitation
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation(organizationId: string, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).deleteInvitation(organizationId, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteUserProfile
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfile(userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).deleteUserProfile(userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listApiKeys
         * @param {string} userProfileId 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApiKeys(userProfileId: string, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfApiKeys> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).listApiKeys(userProfileId, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listCustomerProfiles
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerProfiles(userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfCustomerProfiles> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).listCustomerProfiles(userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Invitation>> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).listInvitations(userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listTrips
         * @param {string} userProfileId 
         * @param {string} [tripType] 
         * @param {string} [tripStatus] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(userProfileId: string, tripType?: string, tripStatus?: string, endDate?: string, startDate?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfTrips> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).listTrips(userProfileId, tripType, tripStatus, endDate, startDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listUserProfileOrganizations
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserProfileOrganizations(userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserProfileOrganization>> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).listUserProfileOrganizations(userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 'listUserProfiles' is deprecated. Please use 'OrganizationsApi.listUserProfiles' instead.
         * @summary listUserProfiles will be replaced by queryUserProfiles
         * @param {string} filter 
         * @param {string} [range] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserProfiles(filter: string, range?: string, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListOfUserProfiles> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).listUserProfiles(filter, range, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveInvitation
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInvitation(organizationId: string, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invitation> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).retrieveInvitation(organizationId, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveUserProfile
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUserProfile(userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserProfile> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).retrieveUserProfile(userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setupCustomerProfile
         * @param {SetupCustomerProfileRequest} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupCustomerProfile(body: SetupCustomerProfileRequest, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SetupCustomerProfileResponse> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).setupCustomerProfile(body, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateInvitation
         * @param {InvitationPayload} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvitation(body: InvitationPayload, organizationId: string, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invitation> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).updateInvitation(body, organizationId, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {UserProfile} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(body: UserProfile, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserProfile> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).updateUserProfile(body, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateUserProfileOrganization
         * @param {UserProfileOrganization} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileOrganization(body: UserProfileOrganization, organizationId: string, userProfileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserProfileOrganization> {
            const localVarFetchArgs = UserprofilesApiFetchParamCreator(configuration).updateUserProfileOrganization(body, organizationId, userProfileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserprofilesApi - factory interface
 * @export
 */
export const UserprofilesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createUserProfile
         * @param {UserProfile} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfile(body: UserProfile, options?: any) {
            return UserprofilesApiFp(configuration).createUserProfile(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteCustomerProfile
         * @param {string} customerProfileId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProfile(customerProfileId: string, userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).deleteCustomerProfile(customerProfileId, userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteInvitation
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation(organizationId: string, userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).deleteInvitation(organizationId, userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteUserProfile
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfile(userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).deleteUserProfile(userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listApiKeys
         * @param {string} userProfileId 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApiKeys(userProfileId: string, sort?: string, options?: any) {
            return UserprofilesApiFp(configuration).listApiKeys(userProfileId, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listCustomerProfiles
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerProfiles(userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).listCustomerProfiles(userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listInvitations
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvitations(userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).listInvitations(userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listTrips
         * @param {string} userProfileId 
         * @param {string} [tripType] 
         * @param {string} [tripStatus] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrips(userProfileId: string, tripType?: string, tripStatus?: string, endDate?: string, startDate?: string, options?: any) {
            return UserprofilesApiFp(configuration).listTrips(userProfileId, tripType, tripStatus, endDate, startDate, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listUserProfileOrganizations
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserProfileOrganizations(userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).listUserProfileOrganizations(userProfileId, options)(fetch, basePath);
        },
        /**
         * 'listUserProfiles' is deprecated. Please use 'OrganizationsApi.listUserProfiles' instead.
         * @summary listUserProfiles will be replaced by queryUserProfiles
         * @param {string} filter 
         * @param {string} [range] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserProfiles(filter: string, range?: string, sort?: string, options?: any) {
            return UserprofilesApiFp(configuration).listUserProfiles(filter, range, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveInvitation
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInvitation(organizationId: string, userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).retrieveInvitation(organizationId, userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveUserProfile
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUserProfile(userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).retrieveUserProfile(userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setupCustomerProfile
         * @param {SetupCustomerProfileRequest} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupCustomerProfile(body: SetupCustomerProfileRequest, userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).setupCustomerProfile(body, userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateInvitation
         * @param {InvitationPayload} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvitation(body: InvitationPayload, organizationId: string, userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).updateInvitation(body, organizationId, userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {UserProfile} body 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(body: UserProfile, userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).updateUserProfile(body, userProfileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateUserProfileOrganization
         * @param {UserProfileOrganization} body 
         * @param {string} organizationId 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileOrganization(body: UserProfileOrganization, organizationId: string, userProfileId: string, options?: any) {
            return UserprofilesApiFp(configuration).updateUserProfileOrganization(body, organizationId, userProfileId, options)(fetch, basePath);
        },
    };
};

/**
 * UserprofilesApi - object-oriented interface
 * @export
 * @class UserprofilesApi
 * @extends {BaseAPI}
 */
export class UserprofilesApi extends BaseAPI {
    /**
     * 
     * @summary createUserProfile
     * @param {UserProfile} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public createUserProfile(body: UserProfile, options?: any) {
        return UserprofilesApiFp(this.configuration).createUserProfile(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteCustomerProfile
     * @param {string} customerProfileId 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public deleteCustomerProfile(customerProfileId: string, userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).deleteCustomerProfile(customerProfileId, userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteInvitation
     * @param {string} organizationId 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public deleteInvitation(organizationId: string, userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).deleteInvitation(organizationId, userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteUserProfile
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public deleteUserProfile(userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).deleteUserProfile(userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listApiKeys
     * @param {string} userProfileId 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public listApiKeys(userProfileId: string, sort?: string, options?: any) {
        return UserprofilesApiFp(this.configuration).listApiKeys(userProfileId, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listCustomerProfiles
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public listCustomerProfiles(userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).listCustomerProfiles(userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listInvitations
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public listInvitations(userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).listInvitations(userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listTrips
     * @param {string} userProfileId 
     * @param {string} [tripType] 
     * @param {string} [tripStatus] 
     * @param {string} [endDate] 
     * @param {string} [startDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public listTrips(userProfileId: string, tripType?: string, tripStatus?: string, endDate?: string, startDate?: string, options?: any) {
        return UserprofilesApiFp(this.configuration).listTrips(userProfileId, tripType, tripStatus, endDate, startDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listUserProfileOrganizations
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public listUserProfileOrganizations(userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).listUserProfileOrganizations(userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 'listUserProfiles' is deprecated. Please use 'OrganizationsApi.listUserProfiles' instead.
     * @summary listUserProfiles will be replaced by queryUserProfiles
     * @param {string} filter 
     * @param {string} [range] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public listUserProfiles(filter: string, range?: string, sort?: string, options?: any) {
        return UserprofilesApiFp(this.configuration).listUserProfiles(filter, range, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveInvitation
     * @param {string} organizationId 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public retrieveInvitation(organizationId: string, userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).retrieveInvitation(organizationId, userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveUserProfile
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public retrieveUserProfile(userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).retrieveUserProfile(userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setupCustomerProfile
     * @param {SetupCustomerProfileRequest} body 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public setupCustomerProfile(body: SetupCustomerProfileRequest, userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).setupCustomerProfile(body, userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateInvitation
     * @param {InvitationPayload} body 
     * @param {string} organizationId 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public updateInvitation(body: InvitationPayload, organizationId: string, userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).updateInvitation(body, organizationId, userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateUserProfile
     * @param {UserProfile} body 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public updateUserProfile(body: UserProfile, userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).updateUserProfile(body, userProfileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateUserProfileOrganization
     * @param {UserProfileOrganization} body 
     * @param {string} organizationId 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilesApi
     */
    public updateUserProfileOrganization(body: UserProfileOrganization, organizationId: string, userProfileId: string, options?: any) {
        return UserprofilesApiFp(this.configuration).updateUserProfileOrganization(body, organizationId, userProfileId, options)(this.fetch, this.basePath);
    }

}
